.card {
  position: relative;
  width: 400px;
  height: 120px;
  border-radius: 10px;
  padding: 0 50px;
  margin: 50px auto;
  background-color: rgb(162, 166, 178);
}
.title {
  font-family: sans-serif;
  font-weight: 100;
  text-transform: uppercase;
  padding: 10px;
  text-align: center;
  letter-spacing: 1px;
}
.scale {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: space-between;
}

.scale_item {
  color: rgb(57, 48, 49);
  font-family: sans-serif;
  font-weight: 300;
}